<template>
  <div>
    <div class="wrapper">
      <div
        v-for="(team, i) in teams"
        :key="'all-teams_'+i"
        style="display: flex; flex-direction: row; align-items: center; margin-top: 20px; margin-bottom: 0!important;"
        class="card"
      >
        <div class="card-header">
          <div><h4
            style="margin-right: 10px"
            class="card-title"
          >
            {{ team.name }}
          </h4></div>
        </div>
        <div
          class="card-body"
          style="display: flex; padding: 0; align-items: center"
        >
          <b-button
            v-b-modal.modal-1
            variant="primary"
            class="mr-1"
            @click="getTeam(team.id)"
          >
            Редактировать команду
          </b-button>
          <div
            style="margin-left: 10px"
            @click="deleteTeam(team.id)"
          >
            <svg
              fill="#7367f0"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            ><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z" /></svg>
          </div>

        </div>

      </div>
      <infinite-loading
        spinner="bubbles"
        @infinite="infiniteScroll"
      />
    </div>
    <b-button class="add-team"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-1
      style="margin-top: 20px"
      variant="outline-primary"
    >
      Добавить команду
    </b-button>
    <b-modal
      id="modal-1"
      ref="teams-modal"
      title="Команда"
      ok-only
      ok-title="Accept"
      hide-footer
    >

      <b-form-group>
        <b-form-input
          v-model="team.name"
          placeholder="Название команды"
          class="inline-block"
        />
      </b-form-group>
      <b-button
        variant="primary"
        class="mr-1"
        @click="updateTeam"
      >
        Сохранить
      </b-button>

    </b-modal>

  </div>
</template>

<script>
import {
  BModal, BButton, VBModal, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import InfiniteLoading from 'vue-infinite-loading'
import crud_module from '@/libs/crud_module'

const teams_module = crud_module({
  entity_name: 'team',
})

export default {
  name: 'TeamsList',
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    InfiniteLoading,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      skipCount: 6,
      maxResultCount: 6,
      team: {
        id: '',
        name: '',
      },
      teams: [],
    }
  },
  created() {
    this.getTeams()
  },
  methods: {
    async infiniteScroll($state) {
      const payload = {
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount,
      }
      await teams_module.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.teams.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async deleteTeam(id) {
      await teams_module.deleteItem(id)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Уведомление',
          icon: 'BellIcon',
          text: 'Команда удалена',
          variant: 'success',
        },
      })
      this.getTeams()
    },
    async getTeams() {
      this.teams = await teams_module.fetchItems().then(r => r.data.rows)
    },

    updateTeam() {
      if (this.team.id) {
        this.saveTeam()
      } else {
        this.createTeam()
      }
    },
    async saveTeam() {
      const result = await teams_module.updateItem(this.team.id, {
        name: this.team.name,

      }).then(r => r.data)
      if (result.success) {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Команда сохранена ',
            variant: 'success',
          },
        })
        this.$refs['teams-modal'].hide()
        this.team = {
          name: '',

        }
        this.getTeams()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка сохранения',
            variant: 'danger',
          },
        })
      }
    },
    async createTeam() {
      const result = await teams_module.createItem({
        name: this.team.name,

      }).then(r => r.data)
      if (result.success) {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Команда сохранена',
            variant: 'success',
          },
        })
        this.$refs['teams-modal'].hide()
        this.team = {
          name: '',
        }
        this.getTeams()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка сохранения',
            variant: 'danger',
          },
        })
      }
    },
    async getTeam(id) {
      const team = await teams_module.fetchItem(id).then(r => r.data)
      this.team = team
    },

  },
}

</script>

<style scoped lang="scss">

svg{
  transition: .3s;
  &:hover{
    transform: scale(1.2);
    cursor: pointer;

  }
}

.add-team{
  position: fixed!important;
  right: 20px;
  bottom: 20px;
  background: #fff;
}

</style>
